<template>
  <div>
    <div class="progressCircular">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="5"
        :value="chartData.value"
        :color="chartData.color"
        class="font-weight-bold text-h4"
      >
        {{ chartData.responded }}/{{ chartData.total }}
      </v-progress-circular>
    </div>
    <div class="progressCircular">
      <v-card-subtitle class="py-0 font-weight-bold"
        >Responses Received</v-card-subtitle
      >
    </div>
  </div>
</template>
<script>
import MemberMixin from "@/mixins/MemberMixin";
export default {
  props: {
    ticket: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [MemberMixin],
  data() {
    return {};
  },
  computed: {
    chartData() {
      let chartData = {};
      chartData.color = "red";
      let result = this.calculateResponsePercentage(this.ticket);
      chartData.value = result.value;
      chartData.responded = result.responded;
      chartData.total = result.total;
      chartData.value === 100
        ? (chartData.color = "green")
        : (chartData.color = "red");
      return chartData;
    },
  },
};
</script>
<style scoped>
.progressCircular {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}
</style>
